<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong class="text-primary">
              Registro de Documentos recibidos
            </strong>
            <div class="card-header-actions">
              <button @click="$refs.modalVideo.mostrarVideo('video-alumno-documentos-registro.mp4')"
                      class="btn btn-outline-info" title="Ver video de ayuda">
                <font-awesome-icon icon="fas fa-question-circle" />
                Ayuda
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <CForm id="frm-hojaruta">
              <template slot="header">
                Codigo de estudiante: {{ $route.params.id }}
              </template>
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <td>Carrera</td>
                    <td><strong>{{ carrera }}</strong></td>
                    <td>Nombres, apellidos</td>
                    <td><strong>{{ inscripcion.nombres }}, {{ inscripcion.apellido1 }} {{
                        inscripcion.apellido2
                      }}</strong></td>
                  </tr>
                  <tr>
                    <td>Tel&eacute;fono/Celular:</td>
                    <td><strong>{{ inscripcion.telefono }}</strong></td>
                    <td>C&oacute;digo del Alumno:</td>
                    <td><strong>{{ inscripcion.codigo_alumno }}</strong></td>
                  </tr>
                  <tr>
                    <td>Nacionalidad:</td>
                    <td><strong>{{ nacionalidad }}</strong></td>
                    <td>C.I./Pasaporte/DNI N°:</td>
                    <td><strong>{{ inscripcion.documento_identificacion }}</strong></td>
                  </tr>
                </table>
              </div>
              <CDataTable
                  hover
                  striped
                  small
                  :items="items"
                  :fields="fields"
                  :items-per-page="10"
                  pagination
                  class="table-sm"
              >
                <template #original="{item}">
                  <td class="text-left">
                    <input class="form-control form-control-sm" type="radio" v-bind:name="'radio_'+item.id"
                           v-bind:id="'radio_o_'+item.id" value="O" @change="mostrarBotones=false"
                           :checked="item.entregado == 'O'"/>
                  </td>
                </template>
                <template #fotocopia="{item}">
                  <td>
                    <input class="form-control form-control-sm" type="radio" v-bind:name="'radio_'+item.id"
                           v-bind:id="'radio_f_'+item.id" value="F" @change="mostrarBotones=false"
                           :checked="item.entregado == 'F'"/>
                  </td>
                </template>
                <template #ninguno="{item}">
                  <td>
                    <input class="form-control form-control-sm" type="radio" v-bind:name="'radio_'+item.id"
                           v-bind:id="'radio_n_'+item.id" value="N" @change="mostrarBotones=false"
                           :checked="item.entregado == 'N'"/>
                  </td>
                </template>
              </CDataTable>

              <div class="row mb-3">
                <div class="col-12 text-center">
                  <CButton color="success" @click="update()" class="mr-2">Grabar datos</CButton>
                  <template v-if="mostrarBotones">
<!--                  <CButton color="outline-danger" @click="reportePdf()" class="mr-2">-->
<!--                    <font-awesome-icon icon="fa-regular fa-file-pdf"/>-->
<!--                    Imprimir Hoja de Ruta-->
<!--                  </CButton>-->
                  </template>
                  <CButton color="primary" @click="goBack">Regresar a la pantalla anterior</CButton>
                </div>
              </div>

            </CForm>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import ModalVideo from "@/views/notifications/ModalVideo";

var modalPdf;

export default {
  name: 'Documentos',
  components: {ModalVideo, ModalPDF, Alerts, Toast, Table},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      fields: ['valor', 'original', 'fotocopia', 'ninguno', 'modificado_el'],
      inscripcion: {},
      carreras: [],
      carrera: '',
      nacionalidades: [],
      nacionalidad: '',
      mostrarBotones: false,
    }
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    reportePdf() {
      axios.get(
          modalPdf.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/documentospdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    update() {
      modalPdf.$refs.alert.show("Grabando datos");
      let myForm = document.getElementById('frm-hojaruta');
      let form_data = new FormData(myForm);
      form_data.append('_method', 'POST');
      axios.post(this.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/documentosgrabar?token=' + localStorage.getItem("api_token"),
          form_data)
          .then(function (response) {
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Documentos registrado/actualizado.', 'success');
            modalPdf.$refs.alert.hide();
            //modalPdf.reportePdf();
            modalPdf.listarDocumentos()
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    listarDocumentos(){
      axios.get(modalPdf.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/documentos?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.items = response.data.documentos;
            modalPdf.mostrarBotones=false;
            response.data.documentos.forEach(function (data, index) {
              if (data.modificado_el !== '') {
                modalPdf.mostrarBotones=true;
                return ;
              }
            });
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    }
  },
  mounted: function () {
    modalPdf = this;
    axios.get(modalPdf.$apiAdress + '/api/inscripcion/0/' + modalPdf.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.inscripcion = response.data.inscripcion;

          axios.get(modalPdf.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
              .then(function (response) {
                let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
                modalPdf.carreras = response.data.carreras;
                modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
                for (let fac in modalPdf.carreras) {
                  for (let carr in modalPdf.carreras[fac]) {
                    if (modalPdf.carreras[fac][carr].value == modalPdf.inscripcion.carrera_id) {
                      modalPdf.carrera = modalPdf.carreras[fac][carr].label;
                    }
                  }
                }
                for (let fac in modalPdf.nacionalidades) {
                  if (modalPdf.nacionalidades[fac].value == modalPdf.inscripcion.nacionalidad_id) {
                    modalPdf.nacionalidad = modalPdf.nacionalidades[fac].label;
                  }
                }
              })
              .catch(function (error) {
                modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
              });
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    modalPdf.listarDocumentos()
  }
}
</script>
